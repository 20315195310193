import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { HOME_URL } from "../../utils/applicationUrl";
import "@fontsource/poppins";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "20px",
    background: "white",
    color: "#4e342ce8",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "11px",
    boxShadow: "0px 3px 3px #00000017",
    marginRight: "20px",
    transition: "all 0.5s",
    "&:hover": {
      background: "rgba(0,0,0,0.1)",
    },
    lineHeight: 1,
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 20,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const location = useLocation();
  const handleClose = (item) => {
    // if (window.location.href.includes("faq")) {
    //   if (
    //     !["en"].includes(item.language_code) &&
    //     !["hi"].includes(item.language_code)
    //   ) {
    //     props.setViewModal(true);
    //     return;
    //   }
    // }
    // dispatch(setLanguage(item))
    localStorage.setItem("SengolLanguage", item.language_code);
    setAnchorEl(null);
    let url = window.location.href.split("/");
    let newUrl = window.location.href;
    if (url.length > 5) {
      url[url.length - 1] = item.language_code;
      newUrl = url.join("/");
    }
    console.log(window.location.href, newUrl, url.length);
    window.location.replace(newUrl);
  };

  const [data, setData] = React.useState();
  const [lang, setLang] = React.useState(
    localStorage.getItem("SengolLanguage")
  );

  React.useEffect(() => {
    const urlLang =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    const urlLang2 =
      window.location.href.split("/")[
        window.location.href.split("/").length - 2
      ];
    console.log(urlLang, urlLang2);
    const something = localStorage.getItem("SengolLanguage");
    if (!something) {
      localStorage.setItem("SengolLanguage", "en");
    }
    axios({
      url: HOME_URL,
      method: "get",
    })
      .then((res) => {
        setData(res.data?.data);
        let index = 1;
        if (urlLang) {
          index = res.data?.data?.findIndex((i) => {
            return urlLang === i.language_code;
          });
          if (index !== -1) {
            if (urlLang === localStorage.getItem("SengolLanguage")) {
              return;
            } else {
              localStorage.setItem("SengolLanguage", urlLang);
              window.location.reload();
            }
          }
        } else if (urlLang2) {
          index = res.data?.data?.findIndex((i) => {
            return urlLang2 === i.language_code;
          });
          if (index !== -1) {
            if (urlLang2 === localStorage.getItem("SengolLanguage")) {
              return;
            } else {
              localStorage.setItem("SengolLanguage", urlLang2);
              window.location.reload();
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [localStorage.getItem("SengolLanguage")]);

  React.useEffect(() => {
    setLang(localStorage.getItem("SengolLanguage"));
  }, [location]);

  return props.notdisplay ? (
    ""
  ) : (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className={classes.button}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {lang
          ? data?.find((i) => i.language_code === lang)?.translated_language
          : "SELECT LANGUAGE"}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {data?.map((item) => (
          <MenuItem
            sx={{ fontFamily: "Poppins", fontSize: "16px !important" }}
            onClick={() => handleClose(item)}
            disableRipple
          >
            {item.translated_language || item.langauge_name}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
