// const BASE_URL = process.env.PUBLIC_URL
// const BASE_URL = "https://insight.sensez9.tec/new-parliament-drupal/web";
// const BASE_URL = "http://10.194.81.230/sengol-web/new-parliament-drupal/web" //NIC
const BASE_URL = "https://sengol.sensez9.tech/new-parliament-drupal/web"; //Sensez
// const BASE_URL = "https://sengol1947ignca.in/new-parliament-drupal/web"; //AWS

export const HOME_URL = `${BASE_URL}/pma/api/v1/choose_languages`;
export const CONTENT_PARTICULARS_URL = `${BASE_URL}/pma/api/v1/sengol_details`;
export const CONTENT_URL = `${BASE_URL}/pma/api/v1/sengol`;
export const VIDEO_DOWNLOAD_URL = `${BASE_URL}/pma/api/v1/download_video`;
export const FAQ_SERVICE = `${BASE_URL}/pma/api/v1/faqdetail?language=`;
// export const NEW_HOMEPAGE = `https://sengol.sensez9.tech/new-parliament-drupal/web/pma/api/v1/home_page_list`;
export const NEW_HOMEPAGE = `${BASE_URL}/pma/api/v1/home_page_list`;
export const HISTORICITY_MENU_PAGE = `${BASE_URL}/pma/api/v1/historyof_sengol_menu?path_alias=/menu-page`;
export const HISTORICITY_PAGE = `${BASE_URL}/pma/api/v1/historicity_of_sengol?path_alias=`;
